<script setup lang="ts">
const emit = defineEmits(['refreshLayout', 'openReminderSidebar'])
const nuxtApp = useNuxtApp()
const clinicStore = useClinicStore()
const remindersRef = ref<HTMLElement | null>(null)
const wasIntersected = ref(false)

const { data, pending, refresh } = useLazyAsyncData(async () => {
  if (!wasIntersected.value) return

  return await nuxtApp.$api.getReminders(
    0,
    3,
    nuxtApp.$dayjs().startOf('day').utc(true).toISOString().split('T')[0],
  )
})

const { stop } = useIntersectionObserver(
  remindersRef,
  ([{ isIntersecting }]) => {
    if (!isIntersecting) return

    wasIntersected.value = isIntersecting
    refresh()
    stop()
  },
)

watch(pending, () => {
  emit('refreshLayout')
})

watch(
  () => clinicStore.websocket.last_reminder_change,
  async () => {
    if (!pending.value) {
      await refresh()
      emit('refreshLayout')
    }
  },
)
</script>

<template>
  <BaseCard ref="remindersRef" title="Lembretes">
    <template #headerIcon>
      <UButton
        icon="i-heroicons-plus-solid"
        variant="soft"
        size="xs"
        @click="emit('openReminderSidebar', 'add')"
      />
    </template>
    <template #content>
      <div v-if="pending" class="space-y-4">
        <USkeleton v-for="i in 2" :key="i" class="h-24" />
      </div>

      <div
        v-else-if="!data?.count"
        class="cursor-pointer transition-colors duration-300 hover:text-sky-500"
        @click="emit('openReminderSidebar', 'add')"
      >
        <BaseEmptyState
          icon="i-heroicons-bell"
          title="Não existem lembretes."
          description="Crie um novo lembrete para ser notificado."
        />
      </div>

      <div v-else class="flex flex-col">
        <div v-for="(reminder, index) in data.items" :key="reminder.id">
          <!-- create divider -->
          <hr v-if="index !== 0" />
          <UiCardReminderItem
            class="cursor-pointer"
            :item="reminder"
            @click="emit('openReminderSidebar', reminder)"
          />
        </div>
      </div>
    </template>

    <template v-if="data?.count" #footer>
      <UButton
        label="Todos os Lembretes"
        variant="ghost"
        icon="i-heroicons-plus-solid"
        block
        color="white"
        @click="emit('openReminderSidebar', 'all')"
      />
    </template>
  </BaseCard>
</template>

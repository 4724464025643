<script setup lang="ts">
const clinicStore = useClinicStore()
</script>

<template>
  <BaseCard title="Profissionais">
    <template #content>
      <div class="grid grid-cols-3 gap-1 text-center xl:grid-cols-5">
        <NuxtLink
          v-for="carer in clinicStore.staff"
          :key="carer.id"
          :to="`/staff/${carer.id}/`"
          class="flex flex-1 flex-col items-center rounded-lg p-2 transition-colors duration-300 hover:bg-sky-50"
        >
          <UiCardCarer :carer="carer" thumbnail-size="2xl" />
        </NuxtLink>
      </div>
    </template>
  </BaseCard>
</template>

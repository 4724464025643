<script setup lang="ts">
import type { PatientList } from '~/types/patient'

const emit = defineEmits(['refreshLayout'])
const nuxtApp = useNuxtApp()
const clinicStore = useClinicStore()

const isLoading = ref(true)
const patients = ref<PatientList[]>()
const patientsRef = ref<HTMLElement | null>(null)
const wasIntersected = ref(false)

async function getLatestPatients() {
  if (!wasIntersected.value) return

  // Only show skeleton if there's no data. To avoid quick reflashes when refreshing data.
  if (!patients.value?.length && !isLoading.value) {
    isLoading.value = true
    await nextTick()
    emit('refreshLayout')
  }

  await nuxtApp.$api
    .getPatients(0, 6)
    .then((response) => {
      patients.value = response.items
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })

  isLoading.value = false
  await nextTick()
  emit('refreshLayout')
}

const { stop } = useIntersectionObserver(
  patientsRef,
  ([{ isIntersecting }]) => {
    if (!isIntersecting) return

    wasIntersected.value = isIntersecting
    getLatestPatients()
    stop()
  },
)

watch(
  () => clinicStore.websocket.last_patient_change,
  async () => {
    await getLatestPatients()
  },
)
</script>

<template>
  <BaseCard ref="patientsRef" title="Pacientes Adicionados">
    <template #content>
      <div
        v-if="isLoading"
        class="grid grid-cols-3 gap-1 text-center xl:grid-cols-6"
      >
        <USkeleton v-for="i in 6" :key="i" class="h-28" />
      </div>

      <NuxtLink
        v-else-if="!patients?.length"
        to="/patients/add/"
        class="transition-colors duration-300 hover:text-sky-500"
      >
        <BaseEmptyState
          icon="i-heroicons-users"
          title="Não existem pacientes nesta clínica."
          description="Adicione um novo paciente."
        />
      </NuxtLink>

      <div v-else class="grid grid-cols-3 gap-1 text-center xl:grid-cols-6">
        <NuxtLink
          v-for="patient in patients"
          :key="patient.id"
          :to="`/patients/${patient.id}/`"
          class="flex flex-1 flex-col rounded-lg p-2 transition-colors duration-300 hover:bg-sky-50"
        >
          <UAvatar
            :src="patient.profile_picture?.small ?? ''"
            :alt="`${patient.first_name} ${patient.last_name}`"
            loading="lazy"
            size="2xl"
            :ui="{
              wrapper:
                'relative mx-auto inline-flex items-center justify-center',
            }"
          />
          <div class="mt-3">
            <UBadge>Paciente</UBadge>
            <h3 class="mt-2 truncate text-sm font-medium text-gray-900">
              {{ patient.first_name }} {{ patient.last_name }}
            </h3>
          </div>
        </NuxtLink>
      </div>
    </template>
  </BaseCard>
</template>

<script setup lang="ts">
import type { Reminder } from '~/types/notification'

defineProps<{
  item: Reminder
}>()

const nuxtApp = useNuxtApp()
</script>

<template>
  <div class="space-x-2">
    <UBadge
      v-if="nuxtApp.$dayjs(item.reminder_date).isToday()"
      color="green"
      label="Hoje"
    />

    <UBadge
      v-else-if="nuxtApp.$dayjs().isBefore(item.reminder_date)"
      :label="nuxtApp.$dayjs(item.reminder_date).fromNow()"
    />

    <UBadge
      v-else-if="!nuxtApp.$dayjs().isBefore(nuxtApp.$dayjs(item.reminder_date))"
      color="amber"
      :label="nuxtApp.$dayjs(item.reminder_date).fromNow()"
    />

    <UBadge
      v-for="carer in item.recipients"
      :key="carer"
      color="teal"
      :label="getCarerName(carer)"
    />

    <UBadge
      v-if="(item.patients?.length ?? 0) > 0"
      color="orange"
      label="Paciente"
    />

    <UBadge
      v-if="item.frequency > 0"
      color="blue"
      :label="`A cada ${item.frequency} ${item.frequency > 1 ? 'dias' : 'dia'}`"
    />
  </div>
</template>
